import React from 'react';
import styled from 'react-emotion';

export default styled('div')`
  text-align: left;
  font-weight: 500;
  color: ${props => (props.textColor ? props.textColor : '#202c39')};
  
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16px;
  }
`;
