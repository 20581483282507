import React, { Fragment } from 'react';
import styled, { css } from 'react-emotion';
import { column, col_xs_12, col_sm_4, col_md_3 } from '../../Import/Grid';
import ItemText from './ItemText';

const ItemColumn = css`
  padding-top: 16px;
  @media screen and (max-width: 767px) {
    padding-bottom: 16px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 24px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding-bottom: 32px;
  }
  @media screen and (min-width: 1280px) {
    padding-bottom: 24px;
  }
  ${column};
  ${col_xs_12};
  ${col_sm_4};
  ${col_md_3};
`;

const ItemLink = styled('a')`
  display: inline-block;
  width: 100%;
  text-decoration: none;
  outline: none;
  cursor: pointer;
`;

const ItemContent = styled('div')`
  padding: 16px;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  text-align: center;
  box-sizing: border-box;
`;

const ItemImg = styled('img')`
  max-width: 100%;
  height: auto;
  vertical-align: top;
  padding-bottom: 8px;
  
  @media screen and (min-width: 1280px) {
    padding-bottom: 16px;
  }
`;

const ItemList = ({ category, categoryCardBackgroundColor, titleTextColor }) => {
  const { title, preview, viewerURL } = category;
  const previewUrl = (
    ((preview || [{ thumbnail: '' }])[0] || { thumbnail: [] }).thumbnail[0] || {
      url: ''
    }
  ).url;

  const itemLink = url => {
    if (!url) {
      return (
        <Fragment>
          <ItemImg src={previewUrl} alt="Image" />
          <ItemText textColor={titleTextColor}>{title}</ItemText>
        </Fragment>
      );
    }

    return (
      <ItemLink href={url}>
        <ItemImg src={previewUrl} alt="Image" />
        <ItemText textColor={titleTextColor}>{title}</ItemText>
      </ItemLink>
    );
  };

  return (
    <div className={ItemColumn}>
      <ItemContent backgroundColor={ categoryCardBackgroundColor }>{itemLink(viewerURL)}</ItemContent>
    </div>
  );
};
export { ItemList };
