import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';

import { Row, Column } from '@common/Grid';
import { Text } from '@common/components/Text';
import Link from '@common/components/Link';
import { StyledIcon } from '@common/components';
import { ArrowLeftIcon, ArrowRightIcon } from '../../assets';

const breakpoints = [768, 1024, 1280];

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`);

const itemsArrow = css({
  cursor: 'pointer',
  color: '#202C39',
  border: '2px solid #202c39',
  borderRadius: '50%',
  opacity: '0.4',
  '&:hover': {
    background: '#202C39',
    color: '#fff',
    opacity: '1'
  },
  '& > svg': {
    verticalAlign: 'top'
  }
});

const MobileUpArrow = css({
  position: 'absolute',
  top: 0,
  [mq[1]]: {
    position: 'static'
  }
});

const itemsArrowLeft = css({
  left: 0
});

const itemsArrowRight = css({
  right: 0
});

const activePage = css({
  color: '#202C39',
  background: 'rgba( 32, 44, 57, 0.05)'
});

const Pages = styled('div')`
  cursor: pointer;
`;

const hideOnMobile = css({
  display: 'none',
  [mq[0]]: {
    display: 'inline-block'
  }
});

const mobileOnly = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const mobileUp = css`
  display: none;

  @media screen and (min-width: 768px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 4px 0;
  }
`;

const mobileArrow = css`
  display: inline-block;
`;

export const propTypes = {
  disabled: PropTypes.bool,
  total: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func,
  pageSize: PropTypes.func
};

/*
variant 1
index = 1  [1,2,3,4,5...99]
index = 2  [1,2,3,4,5...99]
index = 3  [1,2,3,4,5...99]
index = 4  [1,2,3,4,5...99]

variant 2
index = 5  [1...4,5,6...99]
index = 6  [1...5,6,7...99]

variant 3
index = 95 [1...95,96,97,98,99]
index = 97 [1...95,96,97,98,99]
 */

class Pagination extends React.Component {
  state = {
    pages: [],
    pageSize: 20,
    total: 0,
    curPage: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      pageSize: props.pageSize || 20,
      total: props.total || 0,
      curPage: props.page || 1,
      pages: []
    };
  }

  componentDidMount() {
    this.calculatePage();
  }

  componentWillReceiveProps(props) {
    this.setState(
      {
        pageSize: props.pageSize || 20,
        total: props.total || 0,
        curPage: props.page || 1
      },
      this.calculatePage
    );
  }

  calculatePage() {
    let curPage = this.state.curPage;
    const pagesCount = Math.ceil(this.state.total / this.state.pageSize);
    let pages = [];
    // optimistic выводим все страницы
    if (pagesCount <= 7) {
      for (let i = 1; i <= pagesCount; i++) {
        pages.push({
          number: i,
          active: curPage === i
        });
      }
      this.setState({
        pages: pages
      });
      return pages;
    }

    // variant 1
    if (pagesCount > 7 && curPage <= 5) {
      for (let i = 1; i <= 5; i++) {
        pages.push({
          number: i,
          active: curPage === i
        });
      }
      pages.push({
        number: '...'
      });

      pages.push({
        number: pagesCount
      });
      this.setState({
        pages: pages
      });
      return pages;
    }

    // variant 2
    if (pagesCount > 7 && curPage > 5 && curPage < pagesCount - 5) {
      pages.push({
        number: 1
      });

      pages.push({
        number: '...'
      });

      for (let i = curPage - 1; i <= curPage + 1; i++) {
        pages.push({
          number: i,
          active: curPage === i
        });
      }

      pages.push({
        number: '...'
      });

      pages.push({
        number: pagesCount
      });
      this.setState({
        pages: pages
      });
      return pages;
    }

    // variant 3
    if (pagesCount > 7 && curPage > 5 && curPage >= pagesCount - 5) {
      pages.push({
        number: 1
      });

      pages.push({
        number: '...'
      });

      for (let i = pagesCount - 4; i <= pagesCount; i++) {
        pages.push({
          number: i,
          active: curPage === i
        });
      }
    }
    this.setState({
      pages: pages
    });
    return pages;
  }

  render() {
    if (this.state.pages.length <= 1) {
      return null;
    }
    let { page } = this.props;
    const pagesCount = Math.ceil(this.state.total / this.state.pageSize);

    return (
      <Row justifyContent="center">
        <Column
          width={[1, null, 1 / 2, 5 / 12]}
          pt={[4, 3, 2, 3]}
          pb={[2, 3, 4, 3]}
        >
          <div className={mobileOnly}>
            <Pages key={page.number}>
              <Link
                display="inline-block"
                py={1}
                px="13px"
                mr={2}
                className={activePage}
              >
                <Text fontSize={2} lineHeight={1} textAlign="center">
                  {this.state.curPage}
                </Text>
              </Link>
              <Text
                display="inline-block"
                fontSize={2}
                lineHeight={1}
                textAlign="center"
              >
                / {pagesCount}
              </Text>
            </Pages>
            <div>
              <div
                onClick={() => {
                  this.props.onChange(--page);
                }}
                className={mobileArrow}
              >
                <Link
                  display="block"
                  className={itemsArrow}
                  p={['10px', null, '6px']}
                  mr={2}
                >
                  <StyledIcon width={16} height={16}>
                    <ArrowLeftIcon />
                  </StyledIcon>
                </Link>
              </div>
              <div
                onClick={() => {
                  this.props.onChange(++page);
                }}
                className={mobileArrow}
              >
                <Link
                  display="block"
                  className={itemsArrow}
                  p={['10px', null, '6px']}
                >
                  <StyledIcon width={16} height={16}>
                    <ArrowRightIcon />
                  </StyledIcon>
                </Link>
              </div>
            </div>
          </div>
          <div className={mobileUp}>
            <div
              onClick={() => {
                this.props.onChange(--page);
              }}
            >
              <Link
                display="block"
                className={`${itemsArrowLeft} ${itemsArrow} ${MobileUpArrow}`}
                p={['10px', null, '6px']}
                mr={1}
              >
                <StyledIcon width={16} height={16}>
                  <ArrowLeftIcon />
                </StyledIcon>
              </Link>
            </div>
            {this.state.pages.map(page => {
              return (
                (page.active && (
                  <Pages key={page.number}>
                    <Link
                      display="block"
                      py={1}
                      px="13px"
                      mr={1}
                      className={activePage}
                    >
                      <Text fontSize={2} lineHeight={1} textAlign="center">
                        {page.number}
                      </Text>
                    </Link>
                  </Pages>
                )) || (
                  <Pages key={page.number} className={hideOnMobile}>
                    <Link
                      data-page={page.number}
                      display="block"
                      py={1}
                      px="13px"
                      mr={1}
                      onClick={() => {
                        this.props.onChange(page.number);
                      }}
                    >
                      <Text
                        fontSize={2}
                        lineHeight={1}
                        color="#59667D"
                        textAlign="center"
                      >
                        {page.number}
                      </Text>
                    </Link>
                  </Pages>
                )
              );
            })}
            <div
              onClick={() => {
                this.props.onChange(++page);
              }}
            >
              <Link
                display="block"
                className={`${itemsArrowRight} ${itemsArrow} ${MobileUpArrow}`}
                p={['10px', null, '6px']}
              >
                <StyledIcon width={16} height={16}>
                  <ArrowRightIcon />
                </StyledIcon>
              </Link>
            </div>
          </div>
        </Column>
      </Row>
    );
  }
}

Pagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  onChange: PropTypes.func
};

Pagination.defaultProps = {
  page: 1,
  pageSize: 12
};

export default Pagination;
