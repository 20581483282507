import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const MIN_PRICE_WITHOUT_FRACTIONAL = 100000;

const FormattedCurrency = ({ value, currencyDisplay }) => {
  const fractionalDigits = value < MIN_PRICE_WITHOUT_FRACTIONAL ? 2 : 0;
  const currencyDisplayValue = currencyDisplay ? currencyDisplay : "symbol";

  return (
    <FormattedNumber
      value={value}
      format="currency"
      currencyDisplay={currencyDisplayValue}
      minimumFractionDigits={fractionalDigits}
    />
  );
};

FormattedCurrency.propTypes = propTypes;

export { FormattedCurrency };
