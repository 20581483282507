import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { mapProps } from 'recompose';

import { withMainEntitySubscription } from '@common/hoc/withMainEntitySubscription';
import { withFragmentEntitySubscription } from '@common/hoc/withFragmentEntitySubscription';
import { Collection } from '../Collection';
import GET_FRAGMENT from '../../fragment';

const CREATE_CART = gql`
  mutation createCart {
    createCart {
      id
      cart_token
      options {
        cartPageURL
      }
    }
  }
`;

export const PreviewListItemFragment = gql`
  fragment PreviewListItemFragment on Image {
    url
    alt
    title
    thumbnail(formats: [{ id: "224x168", width: 224, height: 168 }]) {
      url
      format_id
    }
  }
`;

export const ProductInListFragment = gql`
  fragment ProductInListFragment on Product {
    id
    title
    description
    preview {
      ... on Image {
        ...PreviewListItemFragment
      }
    }
    taggedBy {
      id
      name
      color
    }
    listPrice {
      formattedValue
      currency
    }
    viewerURL
    netPrice {
      formattedValue
      currency
    }
    quantity {
      value
    }
  }
`;

const CollectionInListFragment = gql`
  fragment CollectionInListFragment on Collection {
    id
    title
    preview {
      __typename
      ... on Image {
        ...PreviewListItemFragment
      }
    }
    viewerURL
  }
`;

const SiteFragment = gql`
  fragment SiteFragment on Site {
    id
    modulesConnection {
      edges {
        node {
          __typename
          ... on CartModule {
            id
          }
          ... on CatalogModule {
            id
          }
        }
      }
    }
  }
`;

const CategoryFragment = gql`
  fragment CategoryFragment on Collection {
    id
    title
    description
    items(limit: 12, offset: $offset) {
      totalCount
      entities {
        __typename
        ... on Product {
          ...ProductInListFragment
        }
        ... on Collection {
          ...CollectionInListFragment
        }
      }
    }
  }
  ${ProductInListFragment}
  ${CollectionInListFragment}
`;

const GET_CATEGORY = gql`
  query getCollection($id: ID!, $offset: Int) {
    collection: node(id: $id) {
      ...CategoryFragment
    }
    site {
      ...SiteFragment
    }
  }
  ${CategoryFragment}
  ${SiteFragment}
  ${PreviewListItemFragment}
`;

const CHANGE_QUANTITY_PRODUCT = gql`
  mutation changeQuantityProduct(
    $cartInput: CartInput
    $product: ProductQuantityInput
  ) {
    changeQuantityProductInCart(cart: $cartInput, product: $product) {
      id
    }
  }
`;

const ContainerWithMainEntitySubscription = withMainEntitySubscription(
  Collection
);

const ContainerWithFragmentEntitySubscription = withFragmentEntitySubscription(
  ContainerWithMainEntitySubscription
);

const ContainerWithEditableProps = mapProps(props => {
  return {
    ...props,
    ...props.fragment.fragmentPayload.fragment.props
  };
})(ContainerWithFragmentEntitySubscription);

export default compose(
  graphql(CREATE_CART, {
    name: 'createCart'
  }),
  graphql(CHANGE_QUANTITY_PRODUCT, {
    name: 'addProduct'
  }),
  graphql(GET_CATEGORY, {
    options: props => {
      const { id, page } = props.rootContext;
      return {
        variables: {
          id,
          offset: ((page || 1) - 1) * 12
        },
        errorPolicy: 'ignore'
      };
    },
    props: ({ data }) => ({ ...data })
  }),
  graphql(GET_FRAGMENT, {
    name: 'fragment',
    options: props => {
      return {
        variables: {
          id: props.fragmentEntity.id
        },
        errorPolicy: 'ignore'
      };
    }
  })
)(ContainerWithEditableProps);
