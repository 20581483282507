import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';

const TextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const StyleText = css`
  font-weight: 400;
  letter-spacing: 0.01em;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 1279px) {
    padding: 16px 0;
  }
  @media screen and (min-width: 1280px) {
    padding: 20px 0;
  }
  ${TextBreak};
`;

const StyledText = styled('div')`
  ${StyleText}
  color: ${props => (props.textColor ? props.textColor : '#59667d')};
`;

const TextList = ({ children, textColor }) => (
  <StyledText
    textColor={textColor}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

TextList.propTypes = {
  children: PropTypes.string
};

export { TextList };
