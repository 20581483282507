import React from "react";
import Store from "@common/data/Store";

const withMainEntitySubscription = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      Store.subscribe({
        entityID: props.rootContext.id,
        blockID: props.rootContext.blockId,
        refetchFunc: props.refetch
      });
    }

    componentWillReceiveProps(nextProps) {
      Store.subscribe({
        entityID: nextProps.rootContext.id,
        blockID: nextProps.rootContext.blockId,
        refetchFunc: nextProps.refetch
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export { withMainEntitySubscription };
