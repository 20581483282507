import React from 'react';
import { col_xs_12 } from '../Import/Grid';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import { ToCartActiveButton } from './ToCartActiveButton';
import Actions from '@common/data/Actions';

const BtnColumn = css`
  ${col_xs_12};
`;
const BtnBlock = css`
  padding-top: 16px;
`;
const CatalogBtn = css`
  display: block;
  width: 100%;
  padding: 12px 5px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  background: #26c485;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.14px;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.12px;
    padding: 8px 5px;
    border-radius: 16px;
    transition: 0.1s ease-out background;
  }
  &:hover {
    background: #29d28f;
  }
  &:focus {
    background: #29d28f;
  }
`;

const OrderButton = styled('div')`
  ${CatalogBtn}
  ${props => props.backgroundColor ? "background-color:" + props.backgroundColor : ""};
  ${props => props.focusBackgroundColor ? " &:hover, &:focus { background-color:" + props.focusBackgroundColor + " }" : ""};
`;

class ToCartButton extends React.Component {
  state = {
    inCart: 0,
    cartInput: {},
    isShowMessage: false
  };

  componentDidMount() {
    const inCart = JSON.parse(localStorage.getItem('inCart')) || {};
    let cartInput = JSON.parse(localStorage.getItem('cartInput'));
    this.setState({
      inCart: inCart[this.props.productId] || 0,
      cartInput: cartInput
    });
  }

  showSuccess() {
      this.setState({
          isShowMessage: true
      });
      setTimeout(() => {
          this.setState({
              isShowMessage: false
          });
      }, 2000);
  }

  handleClick() {
    let cartInput = JSON.parse(localStorage.getItem('cartInput'));
    if (!cartInput) {
      this.props.createCart({}).then(res => {
        cartInput = {
          id: res.data.createCart.id,
          cartToken: res.data.createCart.cart_token,
          options: {
            cartPageURL: res.data.createCart.options.cartPageURL
          }
        };
        localStorage.setItem('cartInput', JSON.stringify(cartInput));
        this.addProduct(cartInput);
      });
    } else {
      this.addProduct(cartInput);
    }
    this.showSuccess();
  }

  addProduct(cartInput) {
    this.props
      .addProduct({
        variables: {
          cartInput,
          product: {
            id: this.props.productId,
            quantity: this.state.inCart + 1
          }
        }
      })
      .then(() => {
        this.setState(
          prevState => {
            return { inCart: (prevState.inCart || 0) + 1, cartInput };
          },
          () => {
            this.updateProductCounter();
            Actions.cartUpdate();
          }
        );
      });
  }

  updateProductCounter() {
    const inCart = JSON.parse(localStorage.getItem('inCart')) || {};
    inCart[this.props.productId] = this.state.inCart;
    localStorage.setItem('inCart', JSON.stringify(inCart));
  }

  render() {
    const label = this.props.inCartButtonLabel ? this.props.inCartButtonLabel : this.props.inCartDefaultLabel;
    return (
      <div className={` ${BtnColumn} ${BtnBlock} `}>
          <OrderButton
              backgroundColor={this.props.orderButtonBackgroundColor}
              focusBackgroundColor={this.state.isShowMessage ? this.props.orderButtonBackgroundColor : this.props.orderButtonFocusBackgroundColor}
              onClick={this.handleClick.bind(this)}>
              {this.state.isShowMessage ? this.props.successLabel : label }
          </OrderButton>
      </div>
    );
  }

  static propTypes = {
    productId: PropTypes.string.isRequired,
    createCart: PropTypes.func.isRequired,
    addProduct: PropTypes.func.isRequired
  };
}

export default ToCartButton;
