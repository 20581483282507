import React from "react";
import PropTypes from "prop-types";

import {
    FloatPanel,
    FrameEditor,
    FrameEditorContent,
} from "@common/components/FloatPanel";
import { rootContext } from "@common/types/rootContext";
import { CategoryList } from "./components/CategoryList";

const Collection = (props) => {
    const { collection, rootContext, site } = props;

    let catalog = {};
    site.modulesConnection.edges.map(({ node: item }) => {
        if (item.__typename === "CatalogModule") {
            catalog = item;
        }
    });

    if (rootContext.mode !== "editor") {
        return <CategoryList {...props} />;
    }

    return (
        <FrameEditor>
            <FloatPanel
                blockId={rootContext.blockId}
                link={{
                    edit: `/admin/catalog/collection/${catalog.id}/${
                        collection?.id || ""
                    }`,
                    editFragment: `/admin/fragment/${props.fragmentEntity.id}`,
                }}
                actions={["delete", "editFragment"]}
            />
            <CategoryList {...props} />
            <FrameEditorContent />
        </FrameEditor>
    );
};

Collection.propTypes = {
    collection: PropTypes.object.isRequired,
    rootContext: rootContext,
    site: PropTypes.object,
    fragmentEntity: PropTypes.object,
};

export { Collection };
