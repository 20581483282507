import gql from "graphql-tag";

const GET_FRAGMENT = gql`
    query pageFragment($id: ID) {
        fragmentPayload(id: $id)
            @rest(
                type: "FragmentPayload"
                path: "/:id"
                endpoint: "fragment"
            ) {
            fragment @type(name: "FragmentPayloadData") {
                id
                props @type(name: "Props") @edit(as: "Panel") {
                    block @edit(label: "Блок") @type(name: "Fragment") {
                        backgroundColor
                            @edit(
                                as: "Color"
                                label: "Цвет фона"
                                value: "#f1f2f8"
                            )
                        titleTextColor
                            @edit(
                                as: "Color"
                                label: "Цвет наименования"
                                value: "#202c39"
                            )
                        descriptionTextColor
                            @edit(
                                as: "Color"
                                label: "Цвет описания"
                                value: "#59667d"
                            )
                    }

                    card
                        @edit(label: "Карточка товара/категории")
                        @type(name: "Fragment") {
                        backgroundColor
                            @edit(
                                as: "Color"
                                label: "Цвет фона"
                                value: "#fff"
                            )
                        titleTextColor
                            @edit(
                                as: "Color"
                                label: "Цвет наименования"
                                value: "#202c39"
                            )
                        priceColor
                            @edit(
                                as: Color
                                label: "Цвет цены"
                                value: "#202c39"
                            )
                    }

                    button @edit(label: "Кнопка") @type(name: "Fragment") {
                        backgroundColor
                            @edit(as: "Color", label: "Цвет", value: "#26c485")
                        focusBackgroundColor
                            @edit(
                                as: "Color"
                                label: "Цвет при наведении"
                                value: "#29d28f"
                            )
                        inCartLabel
                            @edit(
                                as: "SimpleText"
                                label: "Текст на кнопке"
                                value: "в корзину"
                            )
                    }
                }
            }
        }
    }
`;

export default GET_FRAGMENT;
