import React, { Fragment } from 'react';
import { css } from 'react-emotion';
import styled from 'react-emotion';
import FormattedCurrency from '@common/components/LocaleFormatter';

const CatalogPrice = css`
  @media screen and (max-width: 1279px) {
    margin-left: -2px;
    margin-right: -2px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: -3px;
    margin-right: -3px;
  }
`;
const CatalogPriceOld = css`
  display: inline-block;
  font-weight: 700;
  vertical-align: top;

  @media screen and (max-width: 1279px) {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (min-width: 1280px) {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 18px;
    line-height: 24px;
  }
`;
const CatalogPriceNew = css`
  display: inline-block;
  text-decoration: line-through;
  color: #59667d;
  vertical-align: top;

  @media screen and (max-width: 1279px) {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
    line-height: 16px;
  }
  @media screen and (min-width: 1280px) {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 13px;
    line-height: 16px;
  }
`;

const StyledCurrency= styled('div')`
  ${CatalogPriceOld}
  ${props => props.textColor ? 'color: ' + props.textColor + ';': ''}
`;

const CategoryPrice = props => {
  const listPrice = props.listPrice || '';
  const netPrice = props.netPrice || '';
  const allPrice = !!listPrice && !!netPrice;

  return (
    <Fragment>
      {listPrice && (
        <div className={CatalogPrice}>
            <StyledCurrency textColor={props.priceColor}>
                <FormattedCurrency value={allPrice ? netPrice : listPrice} />
            </StyledCurrency>
          {allPrice && (
            <div className={CatalogPriceNew}>
              <FormattedCurrency value={listPrice} />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default CategoryPrice;
