import React, { Fragment } from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';

import { row, column, col_xs_12, col_sm_10, col_md_8 } from '../Import/Grid';

const TitleRow = css`
  ${row};
`;

const TitleColumn = css`
  ${column};
  ${col_xs_12};
  ${col_sm_10};
  ${col_md_8};
`;

const TextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const TitleText = css`
  font-weight: 500;
  letter-spacing: 0.01em;

  @media screen and (max-width: 767px) {
    padding: 24px 0 16px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 32px 0 16px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 24px;
    line-height: 32px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 48px 0 24px;
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (min-width: 1280px) {
    padding: 60px 0 28px;
    font-size: 40px;
    line-height: 48px;
  }
  ${TextBreak};
`;

const StyledTitleText = styled('div')`
  ${TitleText}
  color: ${props => (props.textColor ? props.textColor : '#202c39')};
`;

const TitleList = props => {
  const title = props.children || '';
  return (
    <Fragment>
      {title ? (
        <div className={TitleRow}>
          <div className={TitleColumn}>
            <StyledTitleText textColor={props.textColor}>
              {title}
            </StyledTitleText>
          </div>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

TitleList.propTypes = {
  children: PropTypes.string
};

export { TitleList };
