import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';

const ActiveBtnBlock = css`
  display: flex;
  align-items: center;
`;

const ActiveBtn = css`
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
  line-height: 16px;
  @media screen and (max-width: 1023px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media screen and (min-width: 1024px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const ActiveBtnCart = css`
  flex: auto;
  padding-left: 16px;
  background: #ddf7ea;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  color: #31c07e;
  text-transform: uppercase;
  text-align: left;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    letter-spacing: 0.14px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 12px;
    letter-spacing: 0.12px;
  }
  &:hover {
    color: #fff;
    background: #29d28f;
  }
  &:active {
    color: #fff;
    background: #42da9e;
  }
`;

const ActiveBtnInCart = css`
  color: #202c39;
  padding-left: 4px;
`;

const ActiveBtnPlusItem = css`
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #fff;
  background: #26c485;
  &:hover {
    background: #29d28f;
  }
  &:active {
    background: #42da9e;
  }
`;

const InTheCartButton = styled('a')`
  ${ActiveBtn}
  ${ActiveBtnCart}
  text-decoration: none;
  ${props => props.backgroundColor ? "background-color:" + props.backgroundColor : ""};
  ${props => props.focusBackgroundColor ? " &:hover, &:focus { background-color:" + props.focusBackgroundColor + " }" : ""};
`;
const AddOneToCartButton = styled('div')`
  ${ActiveBtn}
  ${ActiveBtnPlusItem}
  ${props => props.backgroundColor ? "background-color:" + props.backgroundColor : ""};
  ${props => props.focusBackgroundColor ? " &:hover, &:focus { background-color:" + props.focusBackgroundColor + " }" : ""};
`;

class ToCartActiveButton extends React.Component {
  static propTypes = {
    cartUrl: PropTypes.string.isRequired,
    addToCart: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired
  };

  render() {
    return (
      <div className={ActiveBtnBlock}>
            <InTheCartButton
                backgroundColor={this.props.inTheCartButtonBackgroundColor}
                focusBackgroundColor={this.props.inTheCartButtonFocusBackgroundColor}
                href={this.props.cartUrl}
            >
                В корзине:
                <span className={ActiveBtnInCart}>{this.props.quantity}</span>
            </InTheCartButton>

          <AddOneToCartButton
              onClick={this.props.addToCart}
              backgroundColor={this.props.addOneToCartButtonBackgroundColor}
              focusBackgroundColor={this.props.addOneToCartButtonFocusBackgroundColor}
          > + 1 </AddOneToCartButton>

      </div>
    );
  }
}

export { ToCartActiveButton };
