import React, { Fragment } from "react";
import styled, { css } from "react-emotion";
import PropTypes from "prop-types";

import { rootContext } from "@common/types/rootContext";
import { container, row } from "../Import/Grid";
import { TextList } from "./TextList";
import { ItemList } from "./ItemList/ItemList";
import { CategoryComponent } from "./CategoryComponent";
import Pagination from "./Pagination/Pagination";
import { TitleList } from "./TitleList";

const StyleCategory = styled("div")`
    font-family: Roboto, sans-serif;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "#f1f2f8"};
    background-image: ${(props) =>
        props.backgroundImage ? props.backgroundImage : ""};

    @media screen and (max-width: 767px) {
        padding: 0 16px 32px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding: 0 24px 24px;
    }
    @media (min-width: 1024px) and (max-width: 1279px) {
        padding-bottom: 32px;
    }
    @media (min-width: 1280px) {
        padding-bottom: 56px;
    }
`;

const StyleCategoryContainer = styled("div")`
    max-width: 72rem;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
`;

const StyleCategoryContainerInner = styled("div")`
    @media screen and (max-width: 1279px) {
        padding-top: 16px;
    }
    @media screen and (min-width: 1280px) {
        padding-top: 20px;
    }
    ${container};
`;

const ItemListRow = css`
    @media screen and (max-width: 1279px) {
        padding-top: 8px;
    }
    @media screen and (min-width: 1280px) {
        padding-top: 12px;
    }
    ${row};
`;

class CategoryList extends React.Component {
    state = {
        refetch: {},
        rootContext: {},
        collection: {},
        site: {},
        page: 1,
    };

    onChange = (page) => {
        let parsedUrl = new URL(window.location.href);
        parsedUrl.searchParams.set(
            `${this.props.rootContext.blockId}.page`,
            page
        );
        window.history.pushState(null, "", parsedUrl.toString());
        this.setState({
            page: page,
        });
        const el = document.getElementById(
            `b-${this.props.rootContext.blockId}`
        );
        el.scrollIntoView();
        const { fetchMore } = this.props;
        const LIMIT_ITEMS = 12;
        fetchMore({
            variables: {
                lime: LIMIT_ITEMS,
                offset: ((page || 1) - 1) * LIMIT_ITEMS,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                return Object.assign({}, prev, {
                    collection: {
                        ...prev.collection,
                        items: {
                            ...prev.collection.items,
                            entities: [
                                ...fetchMoreResult.collection.items.entities,
                            ],
                        },
                    },
                });
            },
        });
    };

    constructor(props) {
        super(props);
        const { rootContext, refetch, collection, site } = props;

        this.state = {
            refetch,
            rootContext,
            collection,
            site,
            page: rootContext.page || 1,
        };
    }

    componentWillReceiveProps(props) {
        const { refetch, collection, site } = props;
        this.setState({ refetch, collection, site });
    }

    render() {
        const { collection = {}, site } = this.state;
        if (collection === null || Object.keys(collection).length === 0) {
            return "";
        }
        const { createCart, addProduct } = this.props;
        const { title, description, items } = collection;
        const blockBackgroundColor = getValueByPath(this.props, [
            "block",
            "backgroundColor",
        ]);
        const blockBackgroundImage = getValueByPath(this.props, [
            "block",
            "backgroundImage",
        ]);
        const orderButtonBackgroundColor = getValueByPath(this.props, [
            "button",
            "backgroundColor",
        ]);
        const orderButtonFocusBackgroundColor = getValueByPath(this.props, [
            "button",
            "focusBackgroundColor",
        ]);
        const inCartLabel = getValueByPath(this.props, [
            "button",
            "inCartLabel",
        ]);
        const inTheCartButtonBackgroundColor = orderButtonBackgroundColor
            ? "rgba(255, 255, 255, 0.7)"
            : "#ddf7ea";
        const inTheCartButtonFocusBackgroundColor = getValueByPath(this.props, [
            "button",
            "backgroundColor",
        ]);
        const addOneToCartButtonBackgroundColor = getValueByPath(this.props, [
            "button",
            "backgroundColor",
        ]);
        const addOneToCartButtonFocusBackgroundColor = getValueByPath(
            this.props,
            ["button", "focusBackgroundColor"]
        );
        const blockTitleTextColor = getValueByPath(this.props, [
            "block",
            "titleTextColor",
        ]);
        const blockDescriptionTextColor = getValueByPath(this.props, [
            "block",
            "descriptionTextColor",
        ]);
        const cardBackgroundColor = getValueByPath(this.props, [
            "card",
            "backgroundColor",
        ]);
        const cardTitleTextColor = getValueByPath(this.props, [
            "card",
            "titleTextColor",
        ]);
        const cardPriceColor = getValueByPath(this.props, [
            "card",
            "priceColor",
        ]);

        const hasCartModule = !!site.modulesConnection.edges.find(
            ({ node: element }) => element.__typename === "CartModule"
        );

        // TODO(@pavlov) сделать отдельный компонент
        const getCategories = (categories = {}) => {
            const { entities } = categories;

            if (!entities || entities === null) {
                return <div>Нет категорий или товаров</div>;
            }

            return entities.map((category) => {
                if (category["__typename"] === "Collection") {
                    return (
                        <ItemList
                            key={category.id}
                            {...{ category }}
                            categoryCardBackgroundColor={cardBackgroundColor}
                            titleTextColor={cardTitleTextColor}
                        />
                    );
                }

                return (
                    <CategoryComponent
                        orderButtonBackgroundColor={orderButtonBackgroundColor}
                        orderButtonFocusBackgroundColor={
                            orderButtonFocusBackgroundColor
                        }
                        inCartButtonLabel={inCartLabel}
                        inTheCartButtonBackgroundColor={
                            inTheCartButtonBackgroundColor
                        }
                        inTheCartButtonFocusBackgroundColor={
                            inTheCartButtonFocusBackgroundColor
                        }
                        addOneToCartButtonBackgroundColor={
                            addOneToCartButtonBackgroundColor
                        }
                        addOneToCartButtonFocusBackgroundColor={
                            addOneToCartButtonFocusBackgroundColor
                        }
                        productCardBackgroundColor={cardBackgroundColor}
                        titleTextColor={cardTitleTextColor}
                        priceColor={cardPriceColor}
                        createCart={createCart}
                        addProduct={addProduct}
                        key={category.id}
                        hasCartModule={hasCartModule}
                        {...{ category }}
                    />
                );
            });
        };

        return (
            <StyleCategory
                backgroundColor={blockBackgroundColor}
                backgroundImage={blockBackgroundImage}
            >
                <StyleCategoryContainer>
                    <StyleCategoryContainerInner>
                        <TitleList textColor={blockTitleTextColor}>
                            {title}
                        </TitleList>
                        <TextList textColor={blockDescriptionTextColor}>
                            {description}
                        </TextList>
                        {(items && (
                            <Fragment>
                                <div className={ItemListRow}>
                                    {getCategories(items)}
                                </div>
                                <Pagination
                                    total={items.totalCount}
                                    page={this.state.page}
                                    pageSize={12}
                                    onChange={this.onChange.bind(this)}
                                />
                            </Fragment>
                        )) || <div>Нет категорий или товаров</div>}
                    </StyleCategoryContainerInner>
                </StyleCategoryContainer>
            </StyleCategory>
        );
    }
}

CategoryList.propTypes = {
    //error: PropTypes.any,
    collection: PropTypes.object.isRequired,
    page: PropTypes.number,
    refetch: PropTypes.func,
    rootContext: rootContext,
    site: PropTypes.object,
    createCart: PropTypes.func.isRequired,
    addProduct: PropTypes.func.isRequired,
    fetchMore: PropTypes.func,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
};

/**
 * Обходит дерево tree по заданному пути path.
 *
 * Если в процессе обхода какая либо нода null или undefined
 * вернёт null
 * Пример
 * const obj = {
 *  "a" : {
 *     "b" : {
 *        "c" : "требуемое значение"
 *      }
 *   }
 * }
 *
 * getValueByPath(obj, ["a", "b", "c"]) => "требуемое значение"
 * getValueByPath(obj, ["a", "D", "c"]) => null
 */
function getValueByPath(tree, path, i = 0) {
    if (undefined === path[i]) {
        return tree;
    }

    if (undefined === tree[path[i]] || null === tree[path[i]]) {
        return null;
    }

    return getValueByPath(tree[path[i]], path, ++i);
}

export { CategoryList };
