import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';

const StyleMarkerAll = css`
  position: absolute;
  left: 0;
  top: -12px;
  margin: -2px;
`;

const StyleMarkerItem = css`
  display: inline-block;
  padding: 2px;
`;

const StyleMarker = css`
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;

  @media screen and (max-width: 1023px) {
    padding: 5px 8px;
    font-size: 12px;
    line-height: 14px;
  }
  @media screen and (min-width: 1024px) {
    padding: 6px 8px;
    font-size: 10px;
    line-height: 12px;
  }
`;

const Tags = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return null;
  }
  return (
    <div className={StyleMarkerAll}>
      {tags.map(tag => (
        <div key={tag.id} className={StyleMarkerItem}>
          <div
            className={`${StyleMarker} ${css({
              backgroundColor: tag.color
            })}`}
          >
            {tag.name}
          </div>
        </div>
      ))}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.array
};

export default Tags;
