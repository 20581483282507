import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
// import { renderComponent } from 'recompose';

import { column, col_xs_12, col_md_3, col_sm_4 } from '../Import/Grid';
import Tags from './Tags';
import ProductPrice from './ProductPrice';
import ToCartButton from './ToCartButton';

const CatalogColumnFirst = css`
  padding-top: 16px;
  @media screen and (max-width: 767px) {
    padding-bottom: 16px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 24px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding-bottom: 32px;
  }
  @media screen and (min-width: 1280px) {
    padding-bottom: 24px;
  }
  ${column};
  ${col_xs_12};
  ${col_sm_4};
  ${col_md_3};
`;
const CatalogItem = css`
  position: relative;
  padding: 16px;
  box-sizing: border-box;
`;
const Product = styled('div')`
  ${CatalogItem}
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
`;
const CatalogLink = css`
  text-decoration: none;
`;
const CatalogImg = css`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  padding-bottom: 16px;
  height: auto;
  vertical-align: top;
`;
const CatalogDescription = css`
  margin: 0;
  padding: 0;
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;
const TextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;
const CatalogItemName = css`
  font-weight: 500;
  min-height: 40px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-height: 60px;
  }
  @media screen and (min-width: 1024px) {
    min-height: 48px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.13px;
  }
`;
const CatalogItemQuantity = css`
  padding-top: 8px;
  padding-bottom: 16px;
  color: #8d99ae;
  font-size: 14px;
  line-height: 16px;
  @media screen and (min-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
const CatalogItemText = css`
  display: inline-block;
`;
const CatalogItemNumber = css`
  display: inline-block;
  color: #59667d;
`;
const CategoryContainerInner = css`
  display: flex;
  flex-wrap: wrap;
`;
const StyledTitle = styled('div')`
  ${CatalogItemName}
  color: ${props => props.textColor ? props.textColor : "#202c39"}
`;

const ItemsImageWithOutLink = ({ preview }) => {
  const previewUrl = (
    ((preview || [{ thumbnail: '' }])[0] || { thumbnail: [] }).thumbnail[0] || {
      url: ''
    }
  ).url;
  return <img className={CatalogImg} src={previewUrl} width="300" />;
};

const ItemsImageAsLink = ({ url, preview }) => (
  <a className={CatalogLink} href={url} title="">
    <ItemsImage preview={preview} />
  </a>
);

const ItemsImage = ({ viewerURL, preview }) => {
  if (!viewerURL) {
    return <ItemsImageWithOutLink preview={preview} />;
  }

  return <ItemsImageAsLink url={viewerURL} preview={preview} />;
};

const CategoryComponent = ({
  category = {},
  createCart,
  addProduct,
  hasCartModule,
  productCardBackgroundColor,
  inCartButtonLabel,
  orderButtonBackgroundColor,
  orderButtonFocusBackgroundColor,
  inTheCartButtonBackgroundColor,
  inTheCartButtonFocusBackgroundColor,
  addOneToCartButtonBackgroundColor,
  addOneToCartButtonFocusBackgroundColor,
  titleTextColor,
  priceColor
}) => {
  const { title, preview, viewerURL } = category;

  return (
    <div className={CatalogColumnFirst}>
      <Product backgroundColor={ productCardBackgroundColor }>
        <Tags tags={category.taggedBy} />
        <ItemsImage viewerURL={viewerURL} preview={preview} />
        <div className={` ${CatalogDescription} ${TextBreak} `}>
          <div>
            {viewerURL ? (
              <a className={CatalogLink} href={viewerURL} title="">
                <StyledTitle textColor={titleTextColor}>{title}</StyledTitle>
              </a>
            ) : (
              <StyledTitle textColor={titleTextColor}>{title}</StyledTitle>
            )}
            {category.quantity && (
              <div className={CatalogItemQuantity}>
                <div className={CatalogItemText}>В наличии:&nbsp;</div>
                <div className={CatalogItemNumber}>
                  {category.quantity.value}
                </div>
              </div>
            )}
          </div>
          <div>
            {category.listPrice && (
              <ProductPrice
                listPrice={category.listPrice.formattedValue}
                netPrice={category.netPrice.formattedValue}
                currency={category.listPrice.currency}
                priceColor={priceColor}
              />
            )}
            {hasCartModule && (
              <div className={CategoryContainerInner}>
                <ToCartButton
                  inCartButtonLabel                      = { inCartButtonLabel }
                  inCartDefaultLabel                     = 'в корзину'
                  successLabel                           = 'товар добавлен'
                  orderButtonBackgroundColor             = { orderButtonBackgroundColor }
                  orderButtonFocusBackgroundColor        = { orderButtonFocusBackgroundColor }
                  inTheCartButtonBackgroundColor         = { inTheCartButtonBackgroundColor }
                  inTheCartButtonFocusBackgroundColor    = { inTheCartButtonFocusBackgroundColor }
                  addOneToCartButtonBackgroundColor      = { addOneToCartButtonBackgroundColor }
                  addOneToCartButtonFocusBackgroundColor = { addOneToCartButtonFocusBackgroundColor }
                  productId={category.id}
                  createCart={createCart}
                  addProduct={addProduct}
                />
              </div>
            )}
          </div>
        </div>
      </Product>
    </div>
  );
};

CategoryComponent.propTypes = {
  category: PropTypes.object,
  hasCartModule: PropTypes.bool,
  createCart: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  productCartBackgroundColor: PropTypes.string,
  orderButtonBackgroundColor : PropTypes.string,
  orderButtonFocusBackgroundColor : PropTypes.string,
  inTheCartButtonBackgroundColor : PropTypes.string,
  inTheCartButtonFocusBackgroundColor : PropTypes.string,
  addOneToCartButtonBackgroundColor : PropTypes.string,
  addOneToCartButtonFocusBackgroundColor : PropTypes.string
};

export { CategoryComponent };
